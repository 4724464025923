import React from 'react'
// import Img from "gatsby-image"
// import { useStaticQuery, graphql } from "gatsby"



const CardContact = ({ name, mail, phone, fax, cabinet }) => {
	// 	const data = useStaticQuery(graphql`
	//     query {
	// 		pictoMail: file(relativePath: { eq: "icons/contact/mail-icon.png" }) {
	// 			childImageSharp {
	// 				fixed(width: 32, height: 32) {
	// 					...GatsbyImageSharpFixed_withWebp
	// 				}
	// 			}
	// 		}
	// 		pictoMobile: file(relativePath: { eq: "icons/contact/mobile-icon.png" }) {
	// 			childImageSharp {
	// 				fixed(width: 32, height: 32) {
	// 					...GatsbyImageSharpFixed_withWebp
	// 				}
	// 			}
	// 		}
	// 		pictoPhone: file(relativePath: { eq: "icons/contact/phone-icon.png" }) {
	// 			childImageSharp {
	// 				fixed(width: 32, height: 32) {
	// 					...GatsbyImageSharpFixed_withWebp
	// 				}
	// 			}
	// 		}
	// 		pictoFax: file(relativePath: { eq: "icons/contact/fax-icon.png" }) {
	// 			childImageSharp {
	// 				fixed(width: 32, height: 32) {
	// 					...GatsbyImageSharpFixed_withWebp
	// 				}
	// 			}
	// 		}
	// 		pictoAddress: file(relativePath: { eq: "icons/contact/address-icon.png" }) {
	// 			childImageSharp {
	// 				fixed(width: 32, height: 32) {
	// 					...GatsbyImageSharpFixed_withWebp
	// 				}
	// 			}
	// 		}
	//     }
	//   `)
	return (
		<>
			<h2 className="card-contact-name">{name}</h2>
			<div className="card-contact-row">
				<div className="card-contact-picto-wrapper">
					{/* <Img className="card-contact-picto" alt={name} fixed={data.pictoMail.childImageSharp.fixed} /> */}
					Email:&nbsp;
				</div>
				<a className="card-contact-link" href={`mailto:${mail}`}>{mail}</a>
			</div>
			<div className="card-contact-row">
				<div className="card-contact-picto-wrapper">
					{/* <Img className="card-contact-picto" alt={name} fixed={data.pictoPhone.childImageSharp.fixed} /> */}
					Tél:&nbsp;
				</div>
				<a className="card-contact-link" href={`tel:${phone}`}>{phone}</a>
			</div>
			<div className="card-contact-row">
				<div className="card-contact-picto-wrapper">
					{/* <Img className="card-contact-picto" alt={name} fixed={data.pictoFax.childImageSharp.fixed} /> */}
					Fax:&nbsp;
				</div>
				<p>{fax}</p>
			</div>
			<div className="card-contact-row">
				<div className="card-contact-picto-wrapper">
					{/* <Img className="card-contact-picto" alt={name} fixed={data.pictoAddress.childImageSharp.fixed} /> */}
					Adresse:&nbsp;
				</div>
				<p className="card-contact-link">{cabinet}</p>
			</div>
		</>
	)
}

export default CardContact