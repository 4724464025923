import React from 'react'
import Img from "gatsby-image"

const ImageSquareLayout = ({ image, position, description, textLines }) => {
	return (
		<div className="square-image-wrapper">
			<Img
				className="square-image-component"
				fluid={image}
			>
			</Img>
			<div className="square-overlay">
				<p style={{ display: "flex" }}>{description}</p>
				{textLines.map((line) => { return <p>{line}</p> })}
			</div>
		</div>
	)
}

export default ImageSquareLayout