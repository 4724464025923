import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import ImageLayout from "../components/image-layout"
import ImageSquareLayout from "../components/image-square-layout"
import Layout from "../components/layout"
import Card from "../components/card"
import SEO from "../components/seo"
import CardContact from "../components/card-contact"

const EquipePage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background/bg-equipe.jpeg" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      image1: file(relativePath: { eq: "team/avocat_ornella_ricciotti.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 600) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      image2: file(relativePath: { eq: "team/avocat_jf_marchi.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 600) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Equipe" />
      <ImageLayout text="équipe" image={data.background.childImageSharp.fluid} />
      <section className="bg-grey">
        <div>
          <Card title="Essere Avocats" text="l'équipe" position="left" />
        </div>
        <Grid style={{ margin: "2rem 0" }} container>
          <Grid style={{ height: "fit-content" }} item xs={12} sm={6}>
            <div className="square-layout">
              <ImageSquareLayout
                image={data.image1.childImageSharp.fluid}
                position="left"
                description="Maître Ornella RICCIOTTI"
                textLines={[
                  "Avocat au Barreau de Marseille",
                  "Associée",
                ]} />
            </div>
            <div style={{ padding: "1rem" }}>
              <h4>Maître Ornella RICCIOTTI</h4>
              <p>L'activité de Me Ornella RICCIOTTI s'oriente exclusivement vers le droit public. Me Ornella RICCIOTTI a suivi une formation universitaire en droit public. Elle est titulaire d’un DEA en « Droit Public Général », d’un DESS en « Droit et Pratique des Contentieux Publics» et d’une maîtrise en « Droit Public ».</p>
              <p>Sa formation universitaire et son exercice dans des cabinets d’avocats de grande structure et de renommée nationale lui permettent de traiter tout dossier relevant du droit public. Plus spécifiquement, ses activités sont orientées vers le droit des contrats et marchés publics, le droit administratif des biens, le droit de l’urbanisme (contentieux administratif/contentieux pénal), le droit de l’environnement, le droit de la préemption et de l’expropriation (phase administrative/phase judiciaire), le droit de la fonction publique, le contentieux électoral et le droit public économique. Elle dispose également d’une expertise sérieuse en matière de droit d’auteur, en conseil et en contentieux.</p>
              <p>Me RICCIOTTI a par ailleurs développé aux côtés de Me MARCHI, une expertise particulière en ce qui concerne les questions relatives à la Loi Littoral. Elle intervient sur des dossiers impliquant Loi littoral, urbanisme et domaine public maritime et notamment la problématique des concessions domaniales, propriétés privées de bord de mer, droit de préemption et permis de construire. Elle peut se fonder sur cette expérience pour fournir une prestation sur tous les aspects de droit public d’un dossier ayant trait au littoral. Elle intervient tant en matière de conseil, de contentieux qu’en matière de négociation auprès de collectivités locales (Départements, Régions, Communes), d’établissements publics, de sociétés d’économie mixte, de sociétés privées et de particuliers.</p>
              <br />
              <h5>FORMATION</h5>
              <p>Certificat d’Aptitude à la Profession d’Avocat</p>
              <p>DEA en Droit Public Général</p>
              <p>DESS en Droit et Pratique des Contentieux Publics</p>
              <p>Maîtrise en Droit public</p>
              <br />
              <h5>LANGUES DE TRAVAIL</h5>
              <p>Français, Anglais, Italien.</p>
            </div>
            <div className="contact-wrapper">
              <CardContact
                name="Ornella Ricciotti"
                mail="o.ricciotti@essere-avocats.com"
                phone="04 84 25 86 71"
                fax="04 84 25 86 70"
                cabinet="3, place d'Estienne d'Orves - 83150 Bandol" />
            </div>
          </Grid>
          <Grid style={{ height: "fit-content" }} item xs={12} sm={6}>
            <div className="square-layout">
              <ImageSquareLayout
                image={data.image2.childImageSharp.fluid}
                position="right"
                description="Maître Jean-François MARCHI"
                textLines={[
                  "Avocat au Barreau de Marseille",
                  "Associé",
                ]} />
            </div>
            <div style={{ padding: "1rem" }}>
              <h4>Maître Jean-François MARCHI</h4>
              <p>Me MARCHI a exercé jusqu’en 2011 au sein d’un important cabinet parisien, classé « Excellent » en droit public (Classement The Legal 500).</p>
              <p>Son activité s’oriente principalement vers le droit public et plus spécifiquement vers le droit des marchés et des travaux publics, le droit public économique et le droit des contrats publics. Il dispose également d’une expertise sur tous les aspects européens et internationaux des dossiers.</p>
              <p>Il est intervenu en qualité de conseil ou au contentieux auprès d’Etats, de plusieurs organisations internationales, de collectivités locales (communes, départements, régions) et intercommunales, de sociétés d’économie mixte, de sociétés privées et de particuliers.</p>
              <p>Me MARCHI a par ailleurs développé, aux côtés de Me RICCIOTTI, une expertise particulière en ce qui concerne les questions relatives à la Loi Littoral. Il intervient sur des dossiers impliquant Loi littoral, urbanisme et domaine public maritime et notamment la problématique des concessions domaniales, propriétés privées de bord de mer, droit de préemption et permis de construire.</p>
              <p>A titre d’exemples, Me MARCHI traite ou a traité des contentieux de grandes structures et d’opérateurs publics de premier plan en matière de gestion d’appels d’offres et de suivi de l’exécution du marché. Il assiste ou a assisté d’importants opérateurs privés dans leurs relations avec des personnes publiques : chantiers navals, société en charge de la gestion d’amodiations, sociétés minières, sociétés de production d’énergie, sociétés de travaux publics, sociétés de transports, sociétés de créations de parcs éoliens, sociétés d’aménagement.</p>
              <p>Il dispose ainsi d’une vision des dossiers de droit public sous les deux angles : celui d’opérateurs publics et celui d’opérateurs privés, ce qui lui permet de défendre au mieux les intérêts de ses clients.</p>
              <p>Me MARCHI est l’auteur de nombreuses publications dans des revues spécialisées (Revue de droit public, Revue française de droit constitutionnel, Annuaire français de Droit international, Jurisclasseur).</p>
              <p>Il est Maître de conférences en droit public à Aix-Marseille Université, responsable du Master I « Droit international, droit européen et droits étrangers » et enseigne notamment au Centre de préparation à l’ENA et aux concours administratifs.</p>
              <p>Me MARCHI est également avocat référencé du Consulat du Royaume d’Espagne à Marseille.</p>
              <br />
              <h5>FORMATION</h5>
              <p>Doctorat en Droit Public</p>
              <p>DEA en Droit International</p>
              <p>Maîtrise en Droit International et Européen</p>
              <br />
              <h5>LANGUES DE TRAVAIL</h5>
              <p>Français, Anglais, Espagnol.</p>
            </div>
            <div className="contact-wrapper">
              <CardContact
                name="Jean-François Marchi"
                mail="jf.marchi@essere-avocats.com"
                phone="04 84 25 86 71"
                fax="04 84 25 86 70"
                cabinet="6 cours Pierre Puget - 13006 MARSEILLE" />
            </div>
          </Grid>
        </Grid>
      </section>
    </Layout>
  )
}

export default EquipePage
